import React, { useLayoutEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
// import ReactPlayer from 'react-player';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import ReactPlayer from 'react-player';
import { Text, Footer, Layout, VideoModal } from '../../shared/ui';
import {
  InnerContainer,
  OuterContainer,
} from '../../shared/ui/Container/Container';
import { PlayIcon } from '../../assets';
import { useModal } from '../../shared/hooks';
import { hideCursor, showCursor } from '../../shared/ui/Cursor';

const BrandedData: React.FC = (props) => {
  // @ts-ignore
  const data = props.data.contentfulFeaturedWork;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const videoModalOpt = useModal();

  // console.log('original detail', data);

  useLayoutEffect(() => {
    setTimeout(() => {
      document.documentElement.scrollTo(0, 0);
    }, 100);
  }, [data]);

  const handlePlayPause = () => {
    setSelectedVideo(
      data?.detailVideos?.[0] ||
        data?.videoPromo?.url ||
        data?.featuredVideo?.url
    );
    videoModalOpt.onOpen();
  };

  return (
    <Layout transparentNav>
      <VideoModal {...videoModalOpt}>
        <div
          className=" w-[95vw] sm:w-[74.5vw] h-[60vh] sm:h-[calc(100vh-130px)]"
          onMouseEnter={hideCursor}
          onMouseLeave={showCursor}
        >
          <ReactPlayer
            className="react-player"
            url={selectedVideo}
            controls
            playing
            width="100%"
            height="100%"
            muted
            loop
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              objectFit: 'contain',
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      </VideoModal>
      <OuterContainer>
        {!data?.originalDevelopmentPreview && data?.videoPromo?.url && (
          <div className="relative h-[45vw] sm:h-[40vw] w-full mb-8 sm:mb-16">
            {!isPlaying && (
              <div
                onClick={handlePlayPause}
                className=" absolute top-0 left-0 z-30 w-full h-full flex items-center justify-center mix-blend-exclusion"
              >
                <div className="w-[clamp(48px,5vw,72px)] h-[clamp(48px,5vw,72px)] bg-white flex items-center justify-center rounded-full">
                  <PlayIcon />
                </div>
              </div>
            )}
            {data?.thumbImgs?.[0] ? (
              <GatsbyImage
                alt={data?.thumbImgs?.[0]?.gatsbyImage}
                objectFit="cover"
                image={data?.thumbImgs?.[0]?.gatsbyImage}
                className="object-cover h-full sm:h-[40vw] w-full mb-11"
              />
            ) : (
              <div className=" w-full h-full">
                {data?.detailVideos?.[0] && (
                  <ReactPlayer
                    className="react-player"
                    url={
                      data.detailVideos?.[0] ||
                      data?.videoPromo?.url ||
                      data?.featuredVideo?.url
                    }
                    controls={false}
                    playing={false}
                    width="100%"
                    height="100%"
                    muted
                    light
                    playIcon={<div />}
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}

        {data.originalDevelopmentPreview && (
          <GatsbyImage
            alt={data.originalDevelopmentPreview.gatsbyImage}
            objectFit="cover"
            image={data.originalDevelopmentPreview.gatsbyImage}
            className="object-contain sm:object-cover h-auto sm:h-[40vw] w-full mb-11"
          />
        )}

        <InnerContainer>
          <div className="flex flex-col md:flex-row">
            <div className="w-[100%] md:w-[70%]">
              <Text type="h6" title={data.subtitle} />
              <Text type="h1" className="mb-11" title={data.title} />

              {!!data.synopsis_description && (
                <div className="flex flex-col md:flex-row">
                  <div className="w-[20%] text-lg mb-11">Synopsis</div>
                  <div className="w-[80%] text-lg mb-11 rich-contentful-text">
                    {renderRichText(data.synopsis_description)}
                  </div>
                </div>
              )}
            </div>

            <div className="w-[100%] md:w-[30%] md:ml-24 md:pl-10 md:border-l md:border-white h-full mb-10">
              {data.client && (
                <div className="mb-8">
                  <p className="uppercase text-sm font-bold">
                    {data?.slug === 'yard-sale' ? 'Writer' : 'Client'}
                  </p>
                  <p className="text-lg">{data.client}</p>
                </div>
              )}

              {data.brand && (
                <div className="mb-8">
                  <p className="uppercase font-bold text-sm">Brand</p>
                  <p className="text-lg">{data.brand}</p>
                </div>
              )}

              {data.agency && (
                <div className="mb-8">
                  <p className="uppercase font-bold text-sm">Agency</p>
                  <p className="text-lg">{data.agency}</p>
                </div>
              )}

              {data.productionCompany && (
                <div className="mb-8">
                  <p className="uppercase font-bold text-sm">
                    Production Company
                  </p>
                  <p className="text-lg">{data.productionCompany}</p>
                </div>
              )}

              {data.director && (
                <div className="mb-8">
                  <p className="uppercase font-bold text-sm">Director</p>
                  <p className="text-lg">{data.director}</p>
                </div>
              )}

              {data.creativeDirector && (
                <div className="mb-8">
                  <p className="uppercase font-bold text-sm">
                    Creative Director
                  </p>
                  <p className="text-lg">{data.creativeDirector}</p>
                </div>
              )}

              {data.services && (
                <div>
                  <p className="uppercase font-bold text-sm">Services</p>
                  <p className="text-lg">{data.services}</p>
                </div>
              )}
            </div>
          </div>

          {data.photos?.map((photo) => (
            <GatsbyImage
              key={photo.contentful_id}
              alt={photo.title}
              image={photo.gatsbyImage}
              className="mb-5 sm:mb-10 h-img-xl"
            />
          ))}
        </InnerContainer>
      </OuterContainer>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    contentfulFeaturedWork(id: { eq: $id }) {
      id
      slug
      title
      subtitle
      client
      agency
      brand
      productionCompany
      director
      creativeDirector
      services
      service
      synopsis_description {
        raw
      }
      videoUrl
      videoPromo {
        url
      }
      description {
        raw
      }
      photos {
        contentful_id
        title
        gatsbyImage(height: 900, width: 1600)
      }
      originalDevelopmentPreview {
        gatsbyImage(height: 1440, width: 2560)
      }
      thumbImgs {
        id
        contentful_id
        gatsbyImage(height: 1440, width: 2560)
      }
      detailVideos
    }
  }
`;

export default BrandedData;
